import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import optimizeImage from "../../static/images/optimize_logo.png";
import dualbootImage from "../../static/images/dualboot-logo.png";
import zellImage from "../../static/images/zell-logo.png"
import ssdImage from "../../static/images/ssd-logo.png"
import sparkImage from "../../static/images/spark-logo.png"

const PartnersPage = () => (
    <Layout>
        <SEO title="Partners"/>

        <div className={"page-header"}>
            <h2>Our Partners</h2>
            <p>We're fortunate to be partnered with the below organizations to help us make speech therapy more accessible and inexpensive.</p>
        </div>

        <div className={"container"}>
            <div className={"content"}>

                <a href="https://www.optimizemi.org/"><img alt={"optiMize"} src={optimizeImage}/></a>
                <a href="https://dualbootpartners.com/"><img alt={"Dualboot Partners"} src={dualbootImage}/></a>
                <a href="http://zli.umich.edu/"><img alt={"Zell Lurie Institute"} src={zellImage}/></a>
                <a href="https://ssd.umich.edu/"><img alt={"University of Michigan Services for Students with Disabilites Department"} src={ssdImage}/></a>
                <a href="https://annarborusa.org/"><img alt={"Ann Arbor SPARK"} src={sparkImage}/></a>
            </div>
        </div>
    </Layout>
)

export default PartnersPage
